@font-face {
    font-family: 'Nexa';
    src: url('./fonts/Nexa-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('./fonts/Nexa-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Nexa';
    src: url('./fonts/Nexa-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

