html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.dashboard {
  display: flex;
  font-family: 'Nexa', sans-serif;
  height: 100vh;
}

.dashboard-content {
  flex: 1;
  overflow-y: auto;
  background-color: #121212;
  color: #ffffff;
  padding-top: 80px;
  position: relative;
  height: 100vh;
  box-sizing: border-box;
}

@media screen and (max-width: 850px) {
  .dashboard-content {
    padding-top: 60px;
  }

  .dashboard-header {
    display: none;
  }
}