.mobile-header {
    display: none;
    background-color: #1E1E20;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
}

.mobile-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 0 5px;
}

.mobile-logo {
    height: 30px;
    width: auto;
}

.mobile-title {
    color: #FFFFFF;
    margin: 0;
    font-size: 1.2rem;
    flex: 1;
    text-align: center;
}

.menu-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-button img {
    width: 24px;
    height: 24px;
}

.mobile-menu {
    background-color: #1E1E20;
    padding: 20px;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    z-index: 1001;
}

.mobile-search-container {
    position: relative;
    width: 100%;
}

.mobile-search-bar {
    width: 90%;
    padding: 10px 15px;
    border: 1px solid #C6FF00;
    border-radius: 20px;
    background-color: #2C2C2E;
    color: #FFFFFF;
    font-size: 14px;
}

.mobile-search-results {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background-color: #2C2C2E;
    border: 1px solid #C6FF00;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1001;
}

.mobile-search-result-item {
    padding: 10px 15px;
    color: #FFFFFF;
    cursor: pointer;
}

.mobile-search-result-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.mobile-nav-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-nav-link {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 12px;
    color: white;
    text-decoration: none;
    border-radius: 8px;
}

.mobile-nav-link.selected {
    background-color: rgba(255, 255, 255, 0.1);
    color: #C6FF00;
}

.mobile-nav-icon {
    width: 24px;
    height: 24px;
}

.mobile-nav-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-notification-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #FF3131;
    color: white;
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0 4px;
}

@media screen and (max-width: 850px) {
    .mobile-header {
        display: block;
    }
}