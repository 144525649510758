/* Main Header Container */
.vh-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #1E1E20;
  position: fixed;
  top: 0;
  right: 0;
  left: 15%; /* Match SideNav width */
  min-height: 10vh;
  z-index: 1000;
  height: 60px; /* Fixed height */
  box-sizing: border-box;
}

/* Add this media query to match SideNav's responsive behavior */
@media screen and (max-width: 1150px) {
  .vh-header {
    left: 70px; /* Match SideNav's collapsed width */
  }
}

/* Header Sections */
.vh-header-left,
.vh-header-right {
  flex: 1;
}

.vh-header-center {
  flex: 2;
  display: flex;
  justify-content: center;
}

/* Page Title */
.vh-page-title {
  margin: 0;
  color: #FFFFFF;
  font-size: 1.5rem;
}

/* Search Section */
.vh-search-container-header {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.vh-search-input-header {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #C6FF00;
  border-radius: 1rem;
  background-color: #2C2C2E;
  color: #FFFFFF;
  font-size: 14px;
}

/* Search Results Dropdown */
.vh-search-results {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: #2C2C2E;
  border: 1px solid #C6FF00;
  border-radius: 20px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 9000;
  width: 405px;
}

.vh-search-result-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #FFFFFF;
}

.vh-search-result-item:hover {
  background-color: #3A3A3C;
}

/* Plan Information */
.vh-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding-right: 20px;
}

.vh-plan-info {
  margin-right: 20px;
  color: #C6FF00;
}

.vh-trial-indicator {
  font-size: 0.8em;
  color: #ff6b6b;
  margin-left: 8px;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  white-space: nowrap;
}

.vh-subscription-indicator {
  font-size: 0.8em;
  color: #C6FF00;
  margin-left: 8px;
  background-color: rgba(198, 255, 0, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  white-space: nowrap;
}

/* Help Icon */
.vh-help-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
}

.vh-help-icon:hover {
  opacity: 0.8;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 850px) {
  .vh-header {
    display: none;
  }
}

/* Add notification styles */
.vh-notification-container {
  position: relative;
}

.vh-notification-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
}

.vh-notification-icon img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.vh-notification-icon svg {
  color: #C6FF00;
}

.vh-notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FF3131;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.vh-notification-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #1E1E20;
  border-radius: 8px;
  width: 320px;
  max-height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border: 1px solid #3A3A3C;
}

.vh-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #3A3A3C;
}

.vh-notification-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
}

.vh-mark-all-read {
  background: none;
  border: none;
  color: #C6FF00;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.vh-mark-all-read:hover {
  background-color: rgba(198, 255, 0, 0.2);
}

.vh-notification-list {
  overflow-y: auto;
  max-height: 350px;
}

.vh-notification-item {
  display: flex;
  padding: 10px 16px;
  border-bottom: 1px solid #3A3A3C;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
}

.vh-notification-item:hover {
  background-color: #2C2C2E;
}

.vh-notification-item.unread {
  background-color: rgba(198, 255, 0, 0.08);
}

.vh-notification-item.unread:hover {
  background-color: rgba(198, 255, 0, 0.15);
}

.vh-notification-icon {
  margin-right: 12px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vh-notification-content {
  flex: 1;
}

.vh-notification-title {
  margin: 0 0 4px 0;
  font-size: 14px;
  font-weight: 600;
  color: #C6FF00;
}

.vh-notification-message {
  margin: 0 0 6px 0;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 1.3;
}

.vh-notification-time {
  font-size: 11px;
  color: #AAAAAA;
}

.vh-notification-dot {
  width: 8px;
  height: 8px;
  background-color: #C6FF00;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 16px;
}

.vh-no-notifications {
  padding: 20px 16px;
  text-align: center;
  color: #AAAAAA;
  font-size: 14px;
}

.notification-dropdown {
  position: absolute;
  top: 65px;
  right: 0;
  width: 350px;
  max-height: 400px;
  background-color: #1E1E20;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  z-index: 10;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #2D2D30;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #2D2D30;
  background-color: #1E1E20;
}

.notification-title {
  font-weight: 600;
  color: #FFFFFF;
  margin: 0;
}

.mark-all-as-read {
  background: none;
  border: none;
  color: #C6FF00;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.mark-all-as-read:hover {
  text-decoration: underline;
}

.notification-body {
  overflow-y: auto;
  max-height: 300px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.vh-notification-footer {
  padding: 10px 15px;
  text-align: center;
  border-top: 1px solid #3A3A3C;
}

.vh-view-all-link {
  color: #C6FF00;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.vh-view-all-link:hover {
  opacity: 0.8;
  text-decoration: underline;
}

/* Remove the old button styling */
.vh-view-all-btn {
  display: none;
}

.notification-item {
  padding: 15px;
  border-bottom: 1px solid #2D2D30;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item.unread {
  background-color: rgba(198, 255, 0, 0.08);
}

.notification-item:hover {
  background-color: #2A2A2C;
}

.notification-item.unread:hover {
  background-color: rgba(198, 255, 0, 0.12);
}

.notification-item-content {
  display: flex;
  width: 100%;
}

.notification-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2A2A2C;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.notification-icon svg {
  color: #C6FF00;
  font-size: 20px;
}

.notification-message {
  flex: 1;
}

.notification-title {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 5px 0;
  color: #FFFFFF;
}

.notification-description {
  font-size: 13px;
  color: #A0A0A0;
  margin: 0;
  line-height: 1.4;
}

.notification-time {
  font-size: 12px;
  color: #777777;
  margin-top: 8px;
  align-self: flex-end;
}

.notification-empty {
  padding: 30px 20px;
  text-align: center;
  color: #777777;
}

.notification-empty svg {
  font-size: 40px;
  color: #2D2D30;
  margin-bottom: 10px;
}

.notification-empty p {
  margin: 0;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  background-color: #FF3131;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  font-weight: bold;
  border: 1px solid #1E1E20;
}

  