/* Main Navigation Container */
.side-nav {
  width: 15%;
  min-width: 100px; /* Prevent too narrow sidebar */
  background-color: #1E1E20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

/* Add this media query for screens between 850px and 950px */
@media screen and (max-width: 950px) {
  .side-nav {
    width: auto;
    min-width: 70px;
  }

  .nav-link span {
    display: none;
  }

  .nav-link {
    justify-content: center;
    padding: 1rem;
  }

  .icon-logo {
    max-width: 40px;
  }
}

/* Logo Section */
.icon-logo-container {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-logo-container .logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon-logo {
  max-width: 5vw;
  min-width: 40px; /* Ensure logo doesn't get too small */
  height: auto;
}

/* Navigation Links Section */
.nav-links {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

/* Individual Navigation Link */
.nav-link {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  text-decoration: none;
  color: #FFFFFF;
  transition: all 0.2s ease;
}

.nav-link span {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 500;
}

.nav-icon {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.8;
}

/* Link States */
.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #C6FF00;
}

.nav-link.selected {
  background-color: rgba(198, 255, 0, 0.1);
  color: #C6FF00;
  border-left: 3px solid #C6FF00;
}

.nav-link.selected .nav-icon {
  opacity: 1;
}

/* Support and Logout Section */
.support-logout {
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 1150px) {
  .side-nav {
    width: auto;
    min-width: 70px;
  }

  .side-nav span {
    display: none;
  }

  .nav-link span {
    display: none;
  }

  .nav-link {
    justify-content: center;
    padding: 1rem;
  }

  .icon-logo {
    max-width: 40px;
  }

}

/* Mobile Styles */
@media screen and (max-width: 850px) {
  .side-nav {
    display: none;
  }
}