@import './fonts.css';

body {
  margin: 0;
  font-family: 'Nexa', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background: #1E1E20;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.light-text {
  font-weight: 200;
}

.heavy-text {
  font-weight: 800;
}